const pages = {
  docs: [
    {
      title: 'Pipy Docs',
			ml: 13,
			mt: -5,
      children: [
				{
				  title: 'About the Docs',
				  href: '/pipy/docs',
				},
				{
				  title: 'Intro to Pipy',
				  href: '/pipy/docs/intro/overview',
				},
				{
				  title: 'Getting Started',
				  href: '/pipy/docs/getting-started/quick-start',
				},
				{
				  title: 'API Reference',
				  href: '/pipy/docs/reference/api',
				},
				{
				  title: 'PipyJS Reference',
				  href: '/pipy/docs/reference/pjs',
				},
				{
				  title: 'Tutorials',
				  href: '/pipy/docs/tutorial/01-hello',
				}
			],
    },
		{
			title: 'FSM Docs',
			href: 'https://fsm-docs.flomesh.io',
			target: '_blank'
		},
  ],
  products: [
    {
      title: 'Pipy',
      href: '/pipy',
    },
    {
      title: 'ZTM',
      href: '/ztm',
    },
    {
      title: 'FLB',
      href: '/flb',
    },
    // {
    //   title: 'OSM Edge',
    //   href: '/osm-edge',
    // },
    {
      title: 'FSM',
      href: '/fsm',
    }
  ],
  githubs: [
    {
      title: 'Pipy',
      href: 'https://github.com/flomesh-io/pipy',
			target: '_blank'
    },
    {
      title: 'ZTM',
      href: 'https://github.com/flomesh-io/ztm',
			target: '_blank'
    },
    {
      title: 'FSM',
      href: 'https://github.com/flomesh-io/fsm',
			target: '_blank'
    }
  ],
};

export default pages;
